import axios, { AxiosRequestConfig } from "axios"

const instance = axios.create({
  timeout: 15000,
})

export function post(url: string, data: any, config?: AxiosRequestConfig) {
  return instance.post(url, data, config)
}

export function get(url: string, params?: any, config?: AxiosRequestConfig) {
  let urlParam = []
  for (let key in params) {
    urlParam.push(`${key}=${params[key]}`)
  }

  return instance.get(`${url}?${urlParam.join("&")}`, config)
}
